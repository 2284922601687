import React from 'react'
import { getPrismicText } from '@utils'
import { Button } from '@components'

import './styles.scss'

const Perks = ({ perksSectionItem, showButton, index }) => {
	
	const {
		perk_image,
		perk_title,
		perk_paragraph,
		perk_cta_label,
		perk_cta_link,
	} = { ...perksSectionItem }

	return (
		<div className={'perks'}>
		  <div className={'perks__image'} data-sal={'fade'} data-sal-delay={'100'} data-sal-easing={'ease'} data-sal-duration={'800'}>
		    <img src={perk_image.url} alt={perk_title}/>  
		  </div>
		  <h3 className={'perks-title'} data-sal={'fade'} data-sal-delay={'150'} data-sal-easing={'ease'} data-sal-duration={'800'}>
		    {getPrismicText(perk_title)}   
		  </h3>
		  <p className={'perks-paragraph'} data-sal={'fade'} data-sal-delay={'200'} data-sal-easing={'ease'} data-sal-duration={'800'}>
		    {getPrismicText(perk_paragraph)}
		  </p>
		  {showButton && <Button category={'primary'} url={getPrismicText(perk_cta_link)}>{getPrismicText(perk_cta_label)}</Button>}
		</div>
	)
}
  
export default Perks