import React from 'react'
import { Link } from 'gatsby'

import './styles.scss'

const Footer = ({data}) => {

  const { footer = {} } = { ...data }
  const {
    logo = '',
    about = '',
    withUs = '',
    ctaLabelPress = '',
    ctaLinkPress = '',
    contact = '',
    logoFacebook = '',
    ctaLinkFaceebook = '',
    logoInstagram = '',
    ctaLinkInstagram = '',
    logoLinkedin = '',
    ctaLinkLinkedin = '',
    copyright = '',
    privacy = '',
    terms = '',
  } = { ...footer }

  return (
    <footer className={'footer'}>
      <div className={'footer__top'}>
        <div className={'footer__top-left'} data-sal={'fade'} data-sal-delay={'50'} data-sal-easing={'ease'} data-sal-duration={'800'}>
          <img src={logo} alt={'logo footer'}/>
        </div>
        <div className={'footer__top-right'} data-sal={'fade'} data-sal-delay={'100'} data-sal-easing={'ease'} data-sal-duration={'800'}>
          <p className={'about'}>{about}</p>
          <p className={'width-us'}>{withUs}</p> 
          <p className={'blog'}>
            <Link to={ctaLinkPress}>{ctaLabelPress}</Link>
          </p>
          <p className={'contact'}>{contact}</p>  
        </div>
      </div>
      <div className={'footer__bottom'}>
        <div className={'footer__bottom-redes'} data-sal={'fade'} data-sal-delay={'150'} data-sal-easing={'ease'} data-sal-duration={'800'}>
          <a href={ctaLinkFaceebook} target={'_blank'} rel={'noreferrer'}><img src={logoFacebook} alt={'logo facebook'}/></a>
          <a href={ctaLinkInstagram} target={'_blank'} rel={'noreferrer'} className={'instagram'}><img src={logoInstagram} alt={'logo instagram'}/></a>
          <a href={ctaLinkLinkedin} target={'_blank'} rel={'noreferrer'}><img src={logoLinkedin} alt={'logo linkedin'}/></a>
        </div>
        <div className={'footer__bottom-terms'}>
          <div className={'copy-right'} data-sal={'fade'} data-sal-delay={'200'} data-sal-easing={'ease'} data-sal-duration={'800'}>
            <p>©{copyright}</p> 
          </div>
          <div className={'terms-policies'} data-sal={'fade'} data-sal-delay={'250'} data-sal-easing={'ease'} data-sal-duration={'800'}>
            <p className={'terms-policies__policies'}>{privacy}</p>
            <p>{terms}</p>
          </div>
        </div>
      </div>
    </footer>   
  )
}
  
export default Footer