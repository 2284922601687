import React from 'react'

import './styles.scss'

const Brands = ({ data }) => {

  const { brands = {}, brandsSectionItems = [] } = { ...data }
  const { title = '' } = { ...brands }

  return (
    <section className='brands'>
      <h2 className='brands__title' data-sal={'fade'} data-sal-delay={'50'} data-sal-easing={'ease'} data-sal-duration={'800'}>{title}</h2>
      <div className='brands__logos'>
        {brandsSectionItems.map((item) => {
          const { brand_image } = { ...item }
          return (
            <div key={brand_image.url} className='brands__logos__image'>
              <img src={brand_image.url} alt={'logo-brand'} data-sal={'fade'} data-sal-delay={'200'} data-sal-easing={'ease'} data-sal-duration={'800'} />
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default Brands