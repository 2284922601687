import React from 'react'
import { Button } from '@components'

import './styles.scss'

const Ready = ({ data }) => {
  const { ready = {} } = { ...data }
  const { title = '', description = '', ctaLink = '', ctaLabel = '' } = {
    ...ready,
  }
  return (
    <section className={'ready-container'}>
      <h2
        data-sal={'fade'}
        data-sal-delay={'150'}
        data-sal-easing={'ease'}
        data-sal-duration={'800'}
      >
        {title}
      </h2>
      <p
        data-sal={'fade'}
        data-sal-delay={'200'}
        data-sal-easing={'ease'}
        data-sal-duration={'800'}
      >
        {description}
      </p>
      <Button category={'primary'} url={ctaLink}>
        {ctaLabel}
      </Button>
    </section>
  )
}

export default Ready
