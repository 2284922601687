import React from 'react'
import { Button } from '@components'

import './styles.scss'

const Hero = ({ section, data }) => {
  const { hero = {} } = { ...data }
  const {
    title = '',
    description = '',
    ctaLink = '',
    ctaLabel = '',
    background = '',
  } = { ...hero }
  return (
    <section className={`container-hero container-hero--${section}`}>
      <div className={`content-hero content-hero--${section}`}>
        <div
          className={'content-hero__elements'}
          data-sal={'fade'}
          data-sal-delay={'200'}
          data-sal-easing={'ease'}
          data-sal-duration={'800'}
        >
          <h1 className={'content-hero__elements-title'}>{title}</h1>
          <p className={'content-hero__elements-paragraph'}>{description}</p>
          <div>
            <Button category={'primary'} url={ctaLink}>
              {ctaLabel}
            </Button>
          </div>
        </div>
      </div>
      <div
        className={`image-hero image-hero--${section}`}
        style={{ backgroundImage: `url('${background}')` }}
      ></div>
    </section>
  )
}

export default Hero
