import { Date } from 'prismic-reactjs'

export const getPrismicText = (node = []) => {
  const [element = {}] = [...node]
  const { text = '' } = { ...element }
  return text
}

export const formatDate = (date, lang) => {
  let defineLocale = lang === 'es-es' ? 'es-ES' : 'en-US'
  let defineLongMonth = lang === 'es-es' ? 'long' : 'short'
  let postDate = Date(date)
  postDate = postDate
    ? new Intl.DateTimeFormat(`${defineLocale}`, {
      month: `${defineLongMonth}`,
      day: '2-digit',
      year: 'numeric',
    }).format(postDate)
    : ''
  return postDate
}