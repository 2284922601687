import React from 'react'

import { Button } from '@components'

import './styles.scss'

const Data = ({ data, section, showButton }) => {

  const { dataSection = {} } = { ...data }
  const {
    title = '',
    description = '',
    ctaLabel = '',
    ctaLink = '', 
    image = '',
  } = { ...dataSection }

  return (
    <section className={'data-container'}>
      <div className={`data-container__content data-container__content--${section}`}>
        <h2 className={`data-container__content-title data-container__content-title--${section}`} data-sal={'fade'} data-sal-delay={'100'} data-sal-easing={'ease'} data-sal-duration={'800'}>{title}</h2>
        <p className={`data-container__content-paragraph data-container__content-paragraph--${section}`} data-sal={'fade'} data-sal-delay={'150'} data-sal-easing={'ease'} data-sal-duration={'800'}>{description}</p>
        <div className={'show-button'}>{showButton && <Button category={'primary'} url={ctaLink}>{ctaLabel}</Button>}</div>
      </div> 
      <div className={`data-container__image data-container__image--${section}`} data-sal={'fade'} data-sal-delay={'50'} data-sal-easing={'ease'} data-sal-duration={'800'}>
        <img src={image} alt={'aspecto de la app'}/>
      </div>
    </section>
  )
}

export default Data
