import React from 'react'

import { getPrismicText } from '@utils'

import './styles.scss'

const Satisfaction = ({ data }) => {

  const { satisfaction = {}, satisfactionSectionItems = [] } = { ...data }
  const { title = '' } = { ...satisfaction }

  return (
    <section className='satisfaction-container'>
      <h2 className='satisfaction-container__title' data-sal={'fade'} data-sal-delay={'50'} data-sal-easing={'ease'} data-sal-duration={'800'}>{title}</h2>
      <div className='satisfaction-container__cards'>
        {satisfactionSectionItems.map((item) => {
          const { satisfaction_image, satisfaction_subtitle, satisfaction_description } = { ...item }
          return (
            <div key={getPrismicText(satisfaction_subtitle)}>
              <div className='satisfaction-container__cards__image'>
                <img src={satisfaction_image.url} alt={getPrismicText(satisfaction_subtitle)}/>
              </div>
              <div className='satisfaction-container__cards__content'>
                <h3 data-sal={'fade'} data-sal-delay={'200'} data-sal-easing={'ease'} data-sal-duration={'800'}>{getPrismicText(satisfaction_subtitle)}</h3>
                <p data-sal={'fade'} data-sal-delay={'300'} data-sal-easing={'ease'} data-sal-duration={'800'}>{getPrismicText(satisfaction_description)}</p>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )

}

export default Satisfaction
