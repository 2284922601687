import React, { useState, useEffect } from 'react'
import { getPrismicText } from '@utils'

import arrowLeft from '../../assets/images/works-arrow-left.svg'
import arrowDown from '../../assets/images/works-arrow-down.svg'

import Carousel from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'

import './styles.scss'

const Works = ({data}) => {

  const { works = {}, worksSectionItems = [] } = { ...data }
  const { title: worksSectionTitle = '', description: worksSectionDescription } = {  ...works }

  const windowGlobal = typeof window !== 'undefined' && window

  const [tab, setTab] = useState(0)
  const [ width, setWidth ] = useState(windowGlobal.innerWidth)

  useEffect(() => {
    const handleResize = () => setWidth(windowGlobal.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  if (typeof window === 'undefined') {
    return null
  }
  
  return (
    <>
      {width > 597 ? (
        <section className={'how-it-works-container'}>
          <div className={'how-it-works-container__head'}>
            <h2 data-sal={'fade'} data-sal-delay={'50'} data-sal-easing={'ease'} data-sal-duration={'800'}>{worksSectionTitle}</h2>
            <p data-sal={'fade'} data-sal-delay={'100'} data-sal-easing={'ease'} data-sal-duration={'800'}>{worksSectionDescription}</p>
          </div>
          <div className={'how-it-works-container__content'}>
            <div className={'how-it-works-container__content-image'} data-sal={'fade'} data-sal-delay={'150'} data-sal-easing={'ease'} data-sal-duration={'800'}>
              {(worksSectionItems || []).map((worksSectionItem, index) => {
                const {
                  works_image,
                  works_title,
                } = { ...worksSectionItem }
                return (
                  <React.Fragment key={`${works_title}-${index}`}>
                    {index === tab && <img src={works_image.url} alt={getPrismicText(works_title)}/>}
                  </React.Fragment>
                )
              })}
            </div>
            <div className={'how-it-works-container__content-list'} data-sal={'fade'} data-sal-delay={'200'} data-sal-easing={'ease'} data-sal-duration={'800'}>
              {(worksSectionItems || []).map((worksSectionItem, index) => {
                const {
                  works_title,
                  works_paragraph,
                } = { ...worksSectionItem }
                return (
                  <div className={'container-list'} key={index}>
                    <div className={'container-list__left'} onClick={ () => setTab(index)} role={'button'} tabIndex={0}  onKeyDown={() => setTab(index)}>
                      <img src={tab === index ? arrowLeft : arrowDown} alt={'arrow'}/> 
                    </div>
                    <div className={'container-list__right'}>
                      <h3>{getPrismicText(works_title)}</h3>
                      {tab === index && (
                        <p>{getPrismicText(works_paragraph)}</p>
                      )}
                    </div>
                  </div>
                )
              })} 
            </div> 
          </div>
        </section>
      ) : (
        <section className={'how-it-works-mobile'}>
          <div className={'how-it-works-mobile__head'}>
            <h2 data-sal={'fade'} data-sal-delay={'50'} data-sal-easing={'ease'} data-sal-duration={'800'}>{worksSectionTitle}</h2>
            <p data-sal={'fade'} data-sal-delay={'100'} data-sal-easing={'ease'} data-sal-duration={'800'}>{worksSectionDescription}</p>
          </div>
          <div className={'how-it-works-carousel-container'}>
            <Carousel autoPlay={7000} infinite dots>
              {(worksSectionItems || []).map((worksSectionItem, index) => {
                const {
                  works_image,
                  works_title,
                  works_paragraph,
                } = { ...worksSectionItem }
                return (
                  <div className={'how-it-works-carousel'} key={index}>
                    <div className={'how-it-works-carousel__image'}>
                      <img src={works_image.url} alt={getPrismicText(works_title)}/> 
                    </div>
                    <div className={'how-it-works-carousel__content'}>
                      <h3>{getPrismicText(works_title)}</h3>
                      <p>{getPrismicText(works_paragraph)}</p>
                    </div>
                  </div>
                )
              })} 
            </Carousel>  
          </div>     
        </section>
      )}
    </>
  )
}

export default Works