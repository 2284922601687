import React from 'react'
import { getPrismicText } from '@utils'

import './styles.scss'

const Offer = ({offerSectionItem}) => {

	const {
	  offer_title,
	  offer_paragraph,
	  offer_image,
	} = { ...offerSectionItem }

	return (
		<div className={'feat'}>
		  <div
		    className={'feat__image'}
		    data-sal={'fade'}
		    data-sal-delay={'200'}
		    data-sal-easing={'ease'}
		    data-sal-duration={'800'}
		  >
		    <img src={offer_image.url} alt={'icono'} />
		  </div>
		  <div className={'feat__content'}>
		    <h3
		      data-sal={'fade'}
		      data-sal-delay={'100'}
		      data-sal-easing={'ease'}
		      data-sal-duration={'800'}
		    >
		      {getPrismicText(offer_title)}
		    </h3>
		    <p
		      data-sal={'fade'}
		      data-sal-delay={'150'}
		      data-sal-easing={'ease'}
		      data-sal-duration={'800'}
		    >
		      {getPrismicText(offer_paragraph)}
		    </p>
		  </div>
		</div>		
	)
}
  
export default Offer
