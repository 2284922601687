import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import './styles.scss'

const Button = ({ children, className, category, url, onClick }) => (
  <Link
    to={url}
    target={'_blank'}
    rel={'noreferrer'}
    className={`button button--${category} ${className}`}
    onClick={onClick}
  >
    {children}
  </Link>
)

Button.propTypes = {
  className: PropTypes.string,
  category: PropTypes.string,
}

Button.defaultProps = {
  className: '', 
  category: 'primary',
}

export default Button
