import React, { useState, useEffect, useRef } from 'react'
import { getPrismicText } from '@utils'

import closeIcon from '@assets/images/close-icon.svg'

import './styles.scss'

const BodyCard = ({ index, bodyCardSectionItem }) => {
  const {
    card_image,
    card_title,
    card_paragraph,
  } = { ...bodyCardSectionItem }

  const [showCard, setShowCard] = useState(false)
  const wrapperRef = useRef(null)

  const handleShowCard = () => setShowCard(!showCard)

  useEffect(() => {
  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowCard(false)
    }
  }
  document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])  

  return (
    <section className={'body-card-container'} onClick={handleShowCard} role={'button'} tabIndex={0} onKeyDown={handleShowCard}>
      <div className={`body-card-container__image body-card-container__image--${index}`}>
        <img src={card_image.url} alt={getPrismicText(card_title)} data-sal={'fade'} data-sal-delay={'100'} data-sal-easing={'ease'} data-sal-duration={'800'}/>
      </div>
      <div className={'body-card-container__title'}>
        <h3 data-sal={'fade'} data-sal-delay={'200'} data-sal-easing={'ease'} data-sal-duration={'800'}>{getPrismicText(card_title)}</h3>
      </div>
      {showCard && (
        <div className={'body-card-container__description'} ref={wrapperRef}>
          <div className={'body-card-container__description-title'}>
            <h3>{getPrismicText(card_title)}</h3>
            <button className={'icon-close'} onClick={handleShowCard}>
              <img
                src={closeIcon}
                alt={'close icon'}
              />
            </button>          
          </div>
          <div className={'body-card-container__description-paragraph'}>
            <p>{getPrismicText(card_paragraph)}</p>    
          </div>
        </div> 
      )}
    </section>
  )
}

export default BodyCard