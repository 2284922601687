import React from 'react';
import { Link } from 'gatsby'
import { HiOutlineExternalLink } from 'react-icons/hi'
import { getPrismicText, formatDate } from '@utils'

const Article = ({ article, pageLang }) => {
  const { 
    article_list_owner = {},
    article_list_cta_label = [], 
    article_list_cta_link = {}, 
    article_list_date = '',
  } = { ...article }
  return (
    <section className={'article'}>
      <div className={'article__owners'}>
        <p className={'date'}>
          <time>{formatDate(article_list_date, pageLang)}</time>
        </p>
        <p className={'owner'}>
          {getPrismicText(article_list_owner)}
        </p>  
      </div>
      <div className={'article__prev'}>
        <Link 
          to={article_list_cta_link.url}
          target={'_blank'}
          rel={'noreferrer'}
          className={'link-container'}
        >
          <p className={'cta-link'}>
            {getPrismicText(article_list_cta_label)}
          </p>
          <div className={'icon'}>
            <HiOutlineExternalLink/>
          </div>
        </Link>    
      </div>
    </section>
  )
}

export default Article;