import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Button } from '@components'
import arrowDown from '@assets/images/arrow-down.svg'
import menuHamburger from '@assets/images/menu-hamburger.svg'
import closeIcon from '@assets/images/close-icon.svg'

import './styles.scss'

const NavBar = ({data}) => {
  const { navbar = {} } = { ...data }
  const {
    logo = '',
    logoMobile = '',
    ctaLabelHome = '',
    ctaLinkHome = '',
    ctaLabelProviders = '',
    ctaLinkProviders = '',
    ctaLabelCompanies = '',
    ctaLinkCompanies = '',
    ctaLabelDemo = '',
    ctaLinkDemo = '',
    ctaLabelLogin = '',
    ctaLinkLogin = '',
    ctaLabelSwitcher = '',
    ctaLinkSwitcher = '',
  } = { ...navbar }

  const windowGlobal = typeof window !== 'undefined' && window

  const [ width, setWidth ] = useState(windowGlobal.innerWidth)
  const [ showNav, setShowNav ] = useState(false)

  const onShowNav = () => setShowNav(!showNav)

  useEffect(() => {
    const handleResize = () => setWidth(windowGlobal.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  useEffect(() => {
    showNav && width <= 768 ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = '')
  }, [showNav, width])

  return (
    <header className={'container'}>
      <div className={'container__header'}>
        <div className={'container__header-logo'}>
          <img
            src={logo}
            alt={'logo healthphy'}
          />
        </div>
        <button className={'container__header-menu'} onClick={onShowNav}>
          <img
            src={menuHamburger}
            alt={'logo menu'}
          />
        </button>
      </div>
      {(showNav || (width > 768)) && (
        <nav className={'container__nav'}>
          <ul>
            <div className={'container-logo-button'}>
              <li className={'container-logo-button__logo'}>
                <Link to={ctaLinkHome} onClick={onShowNav}>
                  <img
                    src={width > 768 ? logo : logoMobile}
                    alt={'logo healthphy'}
                  />
                </Link>
              </li>
              {width <= 768 && (
                <button className={'container-logo-button__close-icon'} onClick={onShowNav}>
                  <img
                    src={closeIcon}
                    alt={'close icon'}
                  />
                </button>
              )}
            </div>
            <div className={'links-container'}>
              <div className={'links-container__left'}>
                {width <= 768 && (
                  <li>
                    <Link to={ctaLinkHome} activeClassName={`${width > 768 ? 'current' : ''}`} onClick={onShowNav}>
                      {ctaLabelHome} 
                    </Link>
                  </li>
                )}
                <li>
                  <Link to={ctaLinkProviders} activeClassName={`${width > 768 ? 'current' : ''}`} onClick={onShowNav}>
                    {ctaLabelProviders} 
                  </Link>
                </li>
                <li>
                  <Link to={ctaLinkCompanies} activeClassName={`${width > 768 ? 'current' : ''}`} onClick={onShowNav}>
                    {ctaLabelCompanies}  
                  </Link>
                </li>
                <li>
                  <Button category={'primary'} url={ctaLinkDemo}>{ctaLabelDemo}</Button>
                </li>
              </div>
              <div className={'links-container__right'}>
                <li>
                  <Button category={'secondary'} url={ctaLinkLogin}>{ctaLabelLogin}</Button>
                </li>
                <li className={'last-child'}>
                  {width > 768 && (
                    <Link to={ctaLinkSwitcher} onClick={onShowNav}>
                      {ctaLabelSwitcher}
                      <img
                        src={arrowDown}
                        alt={'arrow down'}
                        className={'arrow-down'}
                      />
                    </Link>  
                  )}
                </li>
              </div>
            </div>
          </ul>
        </nav>  
      )}
    </header>
  )  
}

export default NavBar
