import React from 'react'
import { getPrismicText } from '@utils'

import './styles.scss'

const Card = ({cardSectionItem, index}) => {
  const {
    card_title,
    card_value,
    card_description,
  } = { ...cardSectionItem }
  return (
    <section className={`card-container card-container--${index}`}>
      <div className={`card-container__title card-container__title--${index}`}>
        <h3 data-sal={'fade'} data-sal-delay={'200'} data-sal-easing={'ease'} data-sal-duration={'800'}>{getPrismicText(card_title)}</h3>
      </div>
      <div className={'card-container__content'}>
        <p className={`card-container__content-value--${index}`} data-sal={'fade'} data-sal-delay={'250'} data-sal-easing={'ease'} data-sal-duration={'800'}>{getPrismicText(card_value)}{index === 1 && <span>%</span>}</p>
        <p className={'card-container__content-description'} data-sal={'fade'} data-sal-delay={'300'} data-sal-easing={'ease'} data-sal-duration={'800'}>{getPrismicText(card_description)}</p>
      </div>
    </section>
  )
}

export default Card