import React from 'react';
import { Article } from '@components'


const ArticleList = ({ data }) => {
  const { allArcticles = [], lang = '' } = { ...data }

  const sortArticles = allArcticles.sort(function(a, b) {
    return new Date(b.article_list_date) - new Date(a.article_list_date)
  })
  return (
    <section className={'press__articles'}>
      {(sortArticles || []).map((article, index) => {
        return (
          <Article 
            key={index} 
            article={article}
            pageLang={lang}
          />
        )
      })}
    </section>
  )
}

export default ArticleList;